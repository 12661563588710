@import '../../styles/settings';

.menu {
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 1rem;
  position: relative;
  overflow-x: hidden;
  padding: 3rem 0;
  height: 100%;
  &--open {
    & > .menu__item:not(.menu__item--active) {
      display: none;
    }
    & > .menu__item--active {
      & > .menu__link {
        display: none;
      }
    }
  }
  &__item {
    cursor:pointer;
    &--submenu {
      .menu__link {
        font-size: 15px;
        html[dir='ltr'] & {
          padding-left: 3.5rem;
        }
        html[dir='rtl'] & {
          padding-right: 3.5rem;
        }
      }
      &-items {
        .menu__link {
          html[dir='ltr'] & {
            padding-left: 4.5rem;
          }
          html[dir='rtl'] & {
            padding-right: 4.5rem;
          }
        }
      }
    }
    &--active {
      > .menu {
        &__link {
          color: $primary;
          background: rgba($primary, 0.1);
          html[dir='ltr'] & {
            border-right: 3px solid $primary;
          }
          html[dir='rtl'] & {
            border-left: 3px solid $primary;
          }
        }
      }
      > .submenu {
        html[dir='ltr'] & {
          left: 0;
        }
        html[dir='rtl'] & {
          right: 0;
        }
        &__items {
          display: block;
        }
      }
      > .dropdown-toggle {
        &:after {
          transform: rotate(180deg);
        }
      }
    }
  }
  &__link {
    text-decoration: none;
    color: $gray-900;
    display: flex;
    align-items: center;
    padding: 15px;
    line-height: 18px;
    html[dir='ltr'] & {
      border-right: 3px solid #fff;
    }
    html[dir='rtl'] & {
      border-left: 3px solid #fff;
    }
    &--light {
      color: $gray-700;
    }
    &:hover {
      color: $primary;
    }
    &:focus {
      outline: none;
    }
  }
  &__icon {
    color: $primary;
    font-size: 1.375em;
    min-width: 23px;
    html[dir='ltr'] & {
      margin-right: 1.25rem;
    }
    html[dir='rtl'] & {
      margin-left: 1.25rem;
    }
    &.icon-dasbhoard {
      font-size: 0.875em;
    }
  }
}

.submenu {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  overflow-y: auto;
  html[dir='ltr'] & {
    left: 290px;
    transition: left 0.5s ease-in-out;
  }
  html[dir='rtl'] & {
    right: 290px;
    transition: right 0.5s ease-in-out;
  }
  &__head {
    padding: 1.5rem 0.9375rem;
    display: flex;
    align-items: center;
  }
  &__list,
  &__items {
    list-style: none;
    padding: 0;
  }
  &__list {
    padding-bottom: 15px;
  }
  &__items {
    display: none;
  }
}

.dropdown-toggle {
  white-space: nowrap;
  transition: transform 0.5s ease-in-out;
  html[dir='ltr'] & {
    padding-right: 2rem;
  }
  html[dir='rtl'] & {
    padding-left: 2rem;
  }
  &:after {
    font-family: 'icomoon' !important;
    content: '\e901';
    font-size: 0.4375rem;
    position: absolute;
    html[dir='ltr'] & {
      right: 12px;
    }
    html[dir='rtl'] & {
      left: 12px;
    }
  }
}

.sidemenu {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 282px;
  position: relative;
  background: #fff;
  align-self: start;
  position: sticky;
  top: 72px;
  height: 92vh;
  height: calc(100vh - 72px);
  overflow: auto;
  @media only screen and (min-width: 992px) {
    min-width: 282px;
  }
  html[dir='ltr'] & {
    border-right: 1px solid $border-color;
  }
  html[dir='rtl'] & {
    border-left: 1px solid $border-color;
  }
  @media only screen and (max-width: 1199.98px) {
    max-width: 275px;
  }
  @media only screen and (max-width: 991.98px) {
    position: fixed;
    top: 0;
    z-index: 101;
    max-width: 260px;
    transition: left 0.5s ease-in-out;
    padding: 1rem 0;
    width: 100%;
    height: 100%;
    html[dir='ltr'] & {
      left: -290px;
      border-right: none;
    }
    html[dir='rtl'] & {
      right: -290px;
      border-left: none;
    }
    .menu {
      padding: 0;
    }
  }
  &__close {
    position: fixed;
    top: 10px;
    html[dir='ltr'] & {
      left: -290px;
    }
    html[dir='rtl'] & {
      right: -290px;
    }
    &.active {
      html[dir='ltr'] & {
        left: 270px;
      }
      html[dir='rtl'] & {
        right: 270px;
      }
    }
  }
  &.active {
    html[dir='ltr'] & {
      left: 0;
    }
    html[dir='rtl'] & {
      right: 0;
    }
  }
}

.back-link {
  padding: 1rem 0.9375rem;
  border-bottom: 1px solid $border-color;
  .link {
    background: transparent;
    outline: none;
    border: none;
    user-select: none;
  }
}

.black-drop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  z-index: 100;
  transition: all 0.3s ease-in-out;
  opacity: 0;
  visibility: hidden;
  &.active {
    opacity: 1;
    visibility: visible;
  }
}
