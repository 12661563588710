@font-face {
  font-family: 'NoirPro';
  src: url(../assets/fonts/NoirPro-Light.woff) format('woff'),
    url(../assets/fonts/NoirPro-Light.eot) format('eot'),
    url(../assets/fonts/NoirPro-Light.ttf) format('truetype'),
    url(../assets/fonts/NoirPro-Light.svg#NoirPro-Light) format('svg');
  font-display: swap;
  font-weight: 300;
}

@font-face {
  font-family: 'NoirPro';
  src: url(../assets/fonts/NoirPro-Regular.woff) format('woff'),
    url(../assets/fonts/NoirPro-Regular.eot) format('eot'),
    url(../assets/fonts/NoirPro-Regular.ttf) format('truetype'),
    url(../assets/fonts/NoirPro-Regular.svg#NoirPro-Regular) format('svg');
  font-display: swap;
  font-weight: 400;
}

@font-face {
  font-family: 'NoirPro';
  src: url(../assets/fonts/NoirPro-Medium.woff) format('woff'),
    url(../assets/fonts/NoirPro-Medium.eot) format('eot'),
    url(../assets/fonts/NoirPro-Medium.ttf) format('truetype'),
    url(../assets/fonts/NoirPro-Medium.svg#NoirPro-Medium) format('svg');
  font-display: swap;
  font-weight: 500;
}

@font-face {
  font-family: 'NoirPro';
  src: url(../assets/fonts/NoirPro-SemiBold.woff) format('woff'),
    url(../assets/fonts/NoirPro-SemiBold.eot) format('eot'),
    url(../assets/fonts/NoirPro-SemiBold.ttf) format('truetype'),
    url(../assets/fonts/NoirPro-SemiBold.svg#NoirPro-SemiBold) format('svg');
  font-display: swap;
  font-weight: 600;
}

@font-face {
  font-family: 'NoirPro';
  src: url(../assets/fonts/NoirPro-Bold.woff) format('woff'),
    url(../assets/fonts/NoirPro-Bold.eot) format('eot'),
    url(../assets/fonts/NoirPro-Bold.ttf) format('truetype'),
    url(../assets/fonts/NoirPro-Bold.svg#NoirPro-Bold) format('svg');
  font-display: swap;
  font-weight: 700;
}

@font-face {
  font-family: 'NoirPro';
  src: url(../assets/fonts/NoirPro-Heavy.woff) format('woff'),
    url(../assets/fonts/NoirPro-Heavy.eot) format('eot'),
    url(../assets/fonts/NoirPro-Heavy.ttf) format('truetype'),
    url(../assets/fonts/NoirPro-Heavy.svg#NoirPro-Heavy) format('svg');
  font-display: swap;
  font-weight: 800;
}

body {
  font-family: 'NoirPro', 'Arial', 'Helvetica Neue', sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
}
