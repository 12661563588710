// //
// // overriding
// // --------------------------------

// @import 'react-redux-toastr/src/styles/index';

$bootstrap-sass-asset-helper: false !default;
$browser-context: 16; // Default
@function rem($pixels, $context: $browser-context) {
  @return #{$pixels/$context}rem;
}

$primary: #028080;
$secondary: #028080;
$gray-100: #f7f7f7;
$gray-400: #868686;
$gray-700: #565656;
$gray-900: #262626;
$border-color: #e8e8e8;
$text-muted: $gray-400;
$text-grey: #666;
$dark: #000;
$white: #fff;
$text-color: #1d1d1d;
$text-grey: #666;
$grey-bg: #fafafb;
$grey-bg-secondary: #f5f6f8;
$footer-text: #3f4141;
$link-color: #028080;
$link-hover-color: #028080;
$font-weight-medium: 500;
$body-bg: #f7f7f7;

$font-family-sans-serif: 'NoirPro', 'Arial', 'Helvetica Neue', sans-serif,
  'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
$font-family-arabic: 'NoirPro', 'Arial', 'Helvetica Neue', sans-serif,
  'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
$font-family-base: 'NoirPro', 'Arial', 'Helvetica Neue', sans-serif,
  'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
$font-size-base: 1rem;
$font-size-lg: 1.0625rem;
$font-size-sm: 0.9375rem;
